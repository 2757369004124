<template>
  <apexchart type="bar" height="200" width="500" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  props:{
    series:{
      type:Array,
      default:()=>{
        return []
      }
    },
    chartOptions:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>