<template>
  <apexchart
    type="donut"
    :options="chartOptions"
    :series="gender_stats"
    title="Total attendance by Gender"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  props: {
    gender_stats: {
      type: Array,
      default: () => {
        return { males: 0, females: 0 };
      },
    },
  },
  data() {
    let chartOptions = {
      chart: {
        type: "donut",
      },
      title: {
        text: "Total Attendance By Gender",
      },
      labels: ["Male", "Female"],
      colors: ["#3F7BB6", "#4CAB94"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
    return {
      chartOptions,
    };
  },
};
</script>

<style></style>
