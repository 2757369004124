<template>
  <div class="pl-2">
    <hr />
    <div  class="container-fluid text-center" v-if="loading">
      <b-spinner class="pt-8"></b-spinner>
    </div>
    <b-row v-if="!loading">
      <b-col>
        <div class="d-flex align-items-center">
          <b-col>
            <bar-graph v-if="this.attendance_per_category.length>0"
              :chartOptions="barChartOptions"
              :series="bar_chart_series()"
            ></bar-graph>
          </b-col>

          <b-col class="border-left">
            <pie-chart v-if="this.gender_stats.males || this.gender_stats.females" :gender_stats="gender_statistics()"></pie-chart>
          </b-col>

          <b-col class="border-left">
            <div>
              <h6 class="text-muted">Average Attendance Rate / Training </h6>
              <h2 class="font-weight-bold">{{ attendance_rate }} %</h2>
            </div>
            <div>
              <h6 class="text-muted">Trained farmers yield</h6>
              <h2 class="text-success">
                {{ trainee_produce }} <small class="text-dark">Tons</small>
              </h2>
            </div>
            <div>
              <h6 class="text-muted">Untrained farmers yield</h6>
              <h2 class="text-primary">
                {{ non_trainee_produce }}
                <small class="text-dark">Tons</small>
              </h2>
            </div>
          </b-col>
        </div>
        <div>
          <div class="d-flex">
            <b-select
              class="form-control form-select mr-2"
              variant="light"
              placeholder="Select Training Category"
              v-model="category"
              :options="filter_categories"
              v-analytics:change="[
                'filter_training_category_reports',
                { element: 'Reports' },
              ]"
              @change="filter_training"
            >
            </b-select>
            <b-form inline>
              <b-input-group prepend="search" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  placeholder="Search Training"
                  v-model="search"
                  @change="filter_training"
                  type="search"
                ></b-form-input>
              </b-input-group>
            </b-form>
          </div>
          <b-table
            class="pt-3"
            :fields="fields"
            :items="items"
            :filter="search"
            sticky-header="40vh"
            @row-clicked="view_training"
          >
            <template #head(progress)>
              <span style="color:#C7DFF6 !important">
                Progress
              </span>
            </template>
            <template #cell(date)="data">
              <span class="font-weight-bold small">
                {{ format_date(data.item.start_date) }}
              </span>
            </template>
            <template #cell(attended)="data" class="text-right">
              <span class="float-left">
                {{ data.item.attendee_no }}
              </span>
              <span class="float-right">
                <b>{{ data.item.attendee_percentage }}%</b>
              </span>
            </template>
            <template #cell(progress)="data">
              <div class="d-flex align-items-center">
                <b-progress
                  :value="data.item.attendee_percentage"
                  variant="info"
                  style="height:10px;"
                  class="w-100"
                ></b-progress>
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BarGraph from "./components/BarGraph.vue";
import PieChart from "./components/PieCharts.vue";
import {
  format_date,
  get_training_categories,
  get_attendance_rate,
  get_attendance_categories,
  get_attendance_gender,
  get_trainee_produce,
  get_training_sessions,
  view_training,
  filter_training,
} from "./actions.js";
export default {
  components: { BarGraph, PieChart },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    url() {
      return (
        this.baseUrl +
        this.$store.state.project_id +
        this.$store.state.url_prefix +
        "/training"
      );
    },
  },
  data() {
    let fields = [
      "date",
      { label: "training", key: "topic" },
      "target",
      "attended",
      "progress",
    ];
    return {
      fields,
      items: [],
      search: "",
      category: null,
      loading:false,
      filter_categories: [],
      gender_stats: {},
      trainee_produce: 0,
      non_trainee_produce: 0,
      attendance_per_category: [],
      attendance_categories: [],
      attendance_rate: 0,
      barChartOptions:{
      chart: {
        type: "bar",
        height: 350,
      },
      title:{
        text:"Average attendance per category"
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: null,
      },
    },
    };
  },
  methods: {
    format_date,
    get_training_sessions,
    view_training,
    filter_training,
    get_training_categories,
    get_attendance_rate,
    get_attendance_categories,
    get_attendance_gender,
    get_trainee_produce,
    gender_statistics() {
      return [this.gender_stats.males, this.gender_stats.females];
    },
    bar_chart_series() {
      return [
        {
          data: this.attendance_per_category,
        },
      ];
    },
  },
  mounted() {
    window.dispatchEvent(new Event('resize'));
    this.get_training_sessions();
    this.get_training_categories();
    this.get_attendance_rate();
    this.get_attendance_categories();
    this.get_attendance_gender();
    this.get_trainee_produce();
  },
};
</script>

<style></style>
