import moment from "moment";
import { axiosApiInstance } from "@/plugins/axios";
import {
  get_training_categories,
  get_training_sessions,
  view_training,
  filter_training,
} from "@/modules/training/subroutes/trainings/actions";

function format_date(date) {
  return date ? moment(date).format("ll") : "-";
}

function get_attendance_rate() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "/reports/attendance/rate",
    method: "get",
  })
    .then((res) => {
      this.attendance_rate = res.data[0].rate;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      console.log(error);
    });
}

function get_attendance_categories() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "/reports/attendance/category",
    method: "get",
  })
    .then((res) => {
      let data = res.data[0];
      this.barChartOptions.xaxis.categories = data.categories;
      this.attendance_per_category = data.average_attendance;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      console.log(error);
    });
}

function get_attendance_gender() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "/reports/attendance/gender",
    method: "get",
  })
    .then((res) => {
      let data = res.data[0];
      this.gender_stats = {
        males: data.males,
        females: data.females,
      };

      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      console.log(error);
    });
}
function get_trainee_produce() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "/reports/trainees/produce",
    method: "get",
  })
    .then((res) => {
      let data = res.data[0];
      this.trainee_produce = data.trained;
      this.non_trainee_produce = data.untrained;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      console.log(error);
    });
}

export {
  format_date,
  get_training_sessions,
  get_attendance_rate,
  get_attendance_categories,
  get_attendance_gender,
  get_trainee_produce,
  view_training,
  filter_training,
  get_training_categories,
};
